import * as React from "react";
import { useState, useEffect } from "react";
import { HeadFC, Link } from "gatsby";
import { Layout, NFTSlider, SocialNetworks } from "../components";

import "../assets/scss/pages/index.scss";
import { EMPTY_IMG_URI, OPENSEA_LAYERS_MARKET, OPENSEA_TEARS_MARKET } from "../helpers/constants";

import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Lazy, Pagination } from "swiper";

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/effect-fade';
import 'swiper/scss/lazy';
import 'swiper/scss/pagination';
import { buildSwiperSlides, ISlide } from "../helpers";

SwiperCore.use([EffectFade, Autoplay, Lazy, Pagination]);

const IndexPage = () => {

	const [collectionNFTCount, setCollectionNFTCount] = useState('');

	const [genesis90DayAveragePrice, setGenesis90DayAveragePrice] = useState('0');
	const [genesis90DayVolume, setGenesis90DayVolume] = useState('0');
	const [tears90DayAveragePrice, setTears90DayAveragePrice] = useState('0');
	const [tears90DayVolume, setTears90DayVolume] = useState('0');

	useEffect(() => {
		let nft_count = 2803;
		setCollectionNFTCount((nft_count / 1000).toFixed(3).replace(/\./, ','));

		setGenesis90DayAveragePrice((Math.random() / 10).toFixed(4));
		setGenesis90DayVolume((Math.random() * 250).toFixed(4));
		setTears90DayAveragePrice((Math.random() / 10).toFixed(4));
		setTears90DayVolume((Math.random() * 250).toFixed(4));
	}, []);

	const heroSliderOptions: SwiperProps = {
		slidesPerView: 1,
		loop: true,
		grabCursor: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		speed: 2500,
		effect: 'fade',
		lazy: {
			checkInView: true,
			enabled: true,
			loadOnTransitionStart: true,
			loadPrevNext: true,
			loadPrevNextAmount: 1,
		},
	};

	const heroTxtSliderOptions: SwiperProps = {
		grabCursor: true,
		pagination: {
			enabled: false,
			clickable: true,
			type: 'bullets',
		},
		autoplay: {
			delay: 3000,
			disableOnInteraction: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 1.2,
				centeredSlides: true,
				pagination: {
					enabled: true,
				},
			},
			1200: {
				slidesPerView: 1,
				centeredSlides: false,
				pagination: {
					enabled: true,
				},
			},
		}
	}

	const vhilsTopSliderOptions: SwiperProps = {
		loop: true,
		pagination: {
			clickable: true,
			el: '.top-slider-wrapper .swiper-pagination-holder .row > div',
			type: 'bullets',
		},
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		lazy: {
			checkInView: true,
			enabled: true,
			loadOnTransitionStart: true,
			loadPrevNext: true,
			loadPrevNextAmount: 1,
		},
	}

	const vhilsSubSliderOptions: SwiperProps = {
		loop: true,
		grabCursor: true,
		slidesPerView: 2,
		slidesPerGroup: 2,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		breakpoints: {
			768: {
				slidesPerView: 4,
				slidesPerGroup: 4,
			},
			1200: {
				slidesPerView: 6,
				slidesPerGroup: 6,
			},
		},
		lazy: {
			checkInView: true,
			enabled: true,
			loadOnTransitionStart: true,
			loadPrevNext: true,
			loadPrevNextAmount: 1,
		},
	};

	const vhilsTopSliderSlides: Array<ISlide> = [
		{
			title: 'Artwork #1',
			img: '/assets/img/temp/whois_vhils_01_xs.jpg',
			img2x: '/assets/img/temp/whois_vhils_01_xs@2x.jpg',
			sources: {
				768: {
					img: '/assets/img/temp/whois_vhils_01_md.jpg',
					img2x: '/assets/img/temp/whois_vhils_01_md@2x.jpg',
				},
				992: {
					img: '/assets/img/temp/whois_vhils_01_lg.jpg',
					img2x: '/assets/img/temp/whois_vhils_01_lg@2x.jpg',
				},
				1200: {
					img: '/assets/img/temp/whois_vhils_01_xl.jpg',
					img2x: '/assets/img/temp/whois_vhils_01_xl@2x.jpg',
				},
			}
		},
		{
			title: 'Artwork #2',
			img: '/assets/img/temp/whois_vhils_02_xs.jpg',
			img2x: '/assets/img/temp/whois_vhils_02_xs@2x.jpg',
			sources: {
				768: {
					img: '/assets/img/temp/whois_vhils_02_md.jpg',
					img2x: '/assets/img/temp/whois_vhils_02_md@2x.jpg',
				},
				992: {
					img: '/assets/img/temp/whois_vhils_02_lg.jpg',
					img2x: '/assets/img/temp/whois_vhils_02_lg@2x.jpg',
				},
				1200: {
					img: '/assets/img/temp/whois_vhils_02_xl.jpg',
					img2x: '/assets/img/temp/whois_vhils_02_xl@2x.jpg',
				},
			}
		},
		{
			title: 'Artwork #3',
			img: '/assets/img/temp/whois_vhils_03_xs.jpg',
			img2x: '/assets/img/temp/whois_vhils_03_xs@2x.jpg',
			sources: {
				768: {
					img: '/assets/img/temp/whois_vhils_03_md.jpg',
					img2x: '/assets/img/temp/whois_vhils_03_md@2x.jpg',
				},
				992: {
					img: '/assets/img/temp/whois_vhils_03_lg.jpg',
					img2x: '/assets/img/temp/whois_vhils_03_lg@2x.jpg',
				},
				1200: {
					img: '/assets/img/temp/whois_vhils_03_xl.jpg',
					img2x: '/assets/img/temp/whois_vhils_03_xl@2x.jpg',
				},
			}
		},
		{
			title: 'Artwork #4',
			img: '/assets/img/temp/whois_vhils_04_xs.jpg',
			img2x: '/assets/img/temp/whois_vhils_04_xs@2x.jpg',
			sources: {
				768: {
					img: '/assets/img/temp/whois_vhils_04_md.jpg',
					img2x: '/assets/img/temp/whois_vhils_04_md@2x.jpg',
				},
				992: {
					img: '/assets/img/temp/whois_vhils_04_lg.jpg',
					img2x: '/assets/img/temp/whois_vhils_04_lg@2x.jpg',
				},
				1200: {
					img: '/assets/img/temp/whois_vhils_04_xl.jpg',
					img2x: '/assets/img/temp/whois_vhils_04_xl@2x.jpg',
				},
			}
		},
		{
			title: 'Artwork #5',
			img: '/assets/img/temp/whois_vhils_05_xs.jpg',
			img2x: '/assets/img/temp/whois_vhils_05_xs@2x.jpg',
			sources: {
				768: {
					img: '/assets/img/temp/whois_vhils_05_md.jpg',
					img2x: '/assets/img/temp/whois_vhils_05_md@2x.jpg',
				},
				992: {
					img: '/assets/img/temp/whois_vhils_05_lg.jpg',
					img2x: '/assets/img/temp/whois_vhils_05_lg@2x.jpg',
				},
				1200: {
					img: '/assets/img/temp/whois_vhils_05_xl.jpg',
					img2x: '/assets/img/temp/whois_vhils_05_xl@2x.jpg',
				},
			}
		},
		{
			title: 'Artwork #6',
			img: '/assets/img/temp/whois_vhils_06_xs.jpg',
			img2x: '/assets/img/temp/whois_vhils_06_xs@2x.jpg',
			sources: {
				768: {
					img: '/assets/img/temp/whois_vhils_06_md.jpg',
					img2x: '/assets/img/temp/whois_vhils_06_md@2x.jpg',
				},
				992: {
					img: '/assets/img/temp/whois_vhils_06_lg.jpg',
					img2x: '/assets/img/temp/whois_vhils_06_lg@2x.jpg',
				},
				1200: {
					img: '/assets/img/temp/whois_vhils_06_xl.jpg',
					img2x: '/assets/img/temp/whois_vhils_06_xl@2x.jpg',
				},
			}
		},
		{
			title: 'Artwork #7',
			img: '/assets/img/temp/whois_vhils_07_xs.jpg',
			img2x: '/assets/img/temp/whois_vhils_07_xs@2x.jpg',
			sources: {
				768: {
					img: '/assets/img/temp/whois_vhils_07_md.jpg',
					img2x: '/assets/img/temp/whois_vhils_07_md@2x.jpg',
				},
				992: {
					img: '/assets/img/temp/whois_vhils_07_lg.jpg',
					img2x: '/assets/img/temp/whois_vhils_07_lg@2x.jpg',
				},
				1200: {
					img: '/assets/img/temp/whois_vhils_07_xl.jpg',
					img2x: '/assets/img/temp/whois_vhils_07_xl@2x.jpg',
				},
			}
		},
	];

	const vhilsSubSlider1Slides: Array<ISlide> = [
		{
			title: 'fundação edp',
			img: '/assets/img/logos/logo_edp.png',
			img2x: '/assets/img/logos/logo_edp.png',
		},
		{
			title: 'Hong Kong Contemporary Art',
			img: '/assets/img/logos/logo_hoca.png',
			img2x: '/assets/img/logos/logo_hoca.png',
		},
		{
			title: 'Contemporary Arts Center',
			img: '/assets/img/logos/logo_cac.png',
			img2x: '/assets/img/logos/logo_cac.png',
		},
		{
			title: 'V&A',
			img: '/assets/img/logos/logo_va.png',
			img2x: '/assets/img/logos/logo_va.png',
		},
		{
			title: 'Centre Pompidou',
			img: '/assets/img/logos/logo_pompidou.png',
			img2x: '/assets/img/logos/logo_pompidou.png',
		},
		{
			title: 'Palais de Tokyo',
			img: '/assets/img/logos/logo_tokyo.png',
			img2x: '/assets/img/logos/logo_tokyo.png',
		},
		{
			title: 'Wynwood Walls',
			img: '/assets/img/logos/logo_wynwood.png',
			img2x: '/assets/img/logos/logo_wynwood.png',
		},
		{
			title: 'CAFA Art Museum',
			img: '/assets/img/logos/logo_cafa.png',
			img2x: '/assets/img/logos/logo_cafa.png',
		},
		{
			title: 'CENT QUATRE #104PARIS',
			img: '/assets/img/logos/logo_centquatre.png',
			img2x: '/assets/img/logos/logo_centquatre.png',
		},
		{
			title: 'Museom of Contenporary Art San Diego',
			img: '/assets/img/logos/logo_sandiego.png',
			img2x: '/assets/img/logos/logo_sandiego.png',
		},
	];

	const vhilsSubSlider2Slides: Array<ISlide> = [
		{
			title: 'VICE',
			img: '/assets/img/logos/logo_vice.png',
			img2x: '/assets/img/logos/logo_vice.png',
		},
		{
			title: 'The Times',
			img: '/assets/img/logos/logo_times.png',
			img2x: '/assets/img/logos/logo_times.png',
		},
		{
			title: 'My Modern Met Media',
			img: '/assets/img/logos/logo_modernmet.png',
			img2x: '/assets/img/logos/logo_modernmet.png',
		},
		{
			title: 'Le Parisien',
			img: '/assets/img/logos/logo_parisien.png',
			img2x: '/assets/img/logos/logo_parisien.png',
		},
		{
			title: 'Al Jazeera',
			img: '/assets/img/logos/logo_aljazeera.png',
			img2x: '/assets/img/logos/logo_aljazeera.png',
		},
		{
			title: 'CBS',
			img: '/assets/img/logos/logo_cbs.png',
			img2x: '/assets/img/logos/logo_cbs.png',
		},
		{
			title: 'CNN',
			img: '/assets/img/logos/logo_cnn.png',
			img2x: '/assets/img/logos/logo_cnn.png',
		},
		{
			title: 'Forbes',
			img: '/assets/img/logos/logo_forbes.png',
			img2x: '/assets/img/logos/logo_forbes.png',
		},
		{
			title: 'HYPEBEAST',
			img: '/assets/img/logos/logo_hypebeast.png',
			img2x: '/assets/img/logos/logo_hypebeast.png',
		},
		{
			title: 'design boom',
			img: '/assets/img/logos/logo_designboom.png',
			img2x: '/assets/img/logos/logo_designboom.png',
		},
	];

	const heroSliderSlides: Array<ISlide> = [
		{
			title: 'Hero slide 01',
			img: '/assets/img/temp/hero_slide01_xs.png',
			img2x: '/assets/img/temp/hero_slide01_xs@2x.png',
			sources: {
				768: {
					img: '/assets/img/temp/hero_slide01_md.png',
					img2x: '/assets/img/temp/hero_slide01_md@2x.png',
				},
				992: {
					img: '/assets/img/temp/hero_slide01_lg.png',
					img2x: '/assets/img/temp/hero_slide01_lg@2x.png',
				},
				1200: {
					img: '/assets/img/temp/hero_slide01_xl.png',
					img2x: '/assets/img/temp/hero_slide01_xl@2x.png',
				},
				1440: {
					img: '/assets/img/temp/hero_slide01_xxl.png',
					img2x: '/assets/img/temp/hero_slide01_xxl@2x.png',
				},
			}
		},
		{
			title: 'Hero slide 02',
			img: '/assets/img/temp/hero_slide02_xs.png',
			img2x: '/assets/img/temp/hero_slide02_xs@2x.png',
			sources: {
				768: {
					img: '/assets/img/temp/hero_slide02_md.png',
					img2x: '/assets/img/temp/hero_slide02_md@2x.png',
				},
				992: {
					img: '/assets/img/temp/hero_slide02_lg.png',
					img2x: '/assets/img/temp/hero_slide02_lg@2x.png',
				},
				1200: {
					img: '/assets/img/temp/hero_slide02_xl.png',
					img2x: '/assets/img/temp/hero_slide02_xl@2x.png',
				},
				1440: {
					img: '/assets/img/temp/hero_slide02_xxl.png',
					img2x: '/assets/img/temp/hero_slide02_xxl@2x.png',
				},
			}
		},
		{
			title: 'Hero slide 03',
			img: '/assets/img/temp/hero_slide03_xs.png',
			img2x: '/assets/img/temp/hero_slide03_xs@2x.png',
			sources: {
				768: {
					img: '/assets/img/temp/hero_slide03_md.png',
					img2x: '/assets/img/temp/hero_slide03_md@2x.png',
				},
				992: {
					img: '/assets/img/temp/hero_slide03_lg.png',
					img2x: '/assets/img/temp/hero_slide03_lg@2x.png',
				},
				1200: {
					img: '/assets/img/temp/hero_slide03_xl.png',
					img2x: '/assets/img/temp/hero_slide03_xl@2x.png',
				},
				1440: {
					img: '/assets/img/temp/hero_slide03_xxl.png',
					img2x: '/assets/img/temp/hero_slide03_xxl@2x.png',
				},
			}
		},
		{
			title: 'Hero slide 04',
			img: '/assets/img/temp/hero_slide04_xs.png',
			img2x: '/assets/img/temp/hero_slide04_xs@2x.png',
			sources: {
				768: {
					img: '/assets/img/temp/hero_slide04_md.png',
					img2x: '/assets/img/temp/hero_slide04_md@2x.png',
				},
				992: {
					img: '/assets/img/temp/hero_slide04_lg.png',
					img2x: '/assets/img/temp/hero_slide04_lg@2x.png',
				},
				1200: {
					img: '/assets/img/temp/hero_slide04_xl.png',
					img2x: '/assets/img/temp/hero_slide04_xl@2x.png',
				},
				1440: {
					img: '/assets/img/temp/hero_slide04_xxl.png',
					img2x: '/assets/img/temp/hero_slide04_xxl@2x.png',
				},
			}
		},
		{
			title: 'Hero slide 05',
			img: '/assets/img/temp/hero_slide05_xs.png',
			img2x: '/assets/img/temp/hero_slide05_xs@2x.png',
			sources: {
				768: {
					img: '/assets/img/temp/hero_slide05_md.png',
					img2x: '/assets/img/temp/hero_slide05_md@2x.png',
				},
				992: {
					img: '/assets/img/temp/hero_slide05_lg.png',
					img2x: '/assets/img/temp/hero_slide05_lg@2x.png',
				},
				1200: {
					img: '/assets/img/temp/hero_slide05_xl.png',
					img2x: '/assets/img/temp/hero_slide05_xl@2x.png',
				},
				1440: {
					img: '/assets/img/temp/hero_slide05_xxl.png',
					img2x: '/assets/img/temp/hero_slide05_xxl@2x.png',
				},
			}
		},
	];

	return (
		<Layout hasNFTSlider={false} hasTeam={true}>
			<picture className={'responsive bg-texture'}>
				<source srcSet={'/assets/img/bkgs/bkg_top_XXL.png 1x, /assets/img/bkgs/bkg_top_XXL@2x.png 2x'} media={'(min-width: 1600px'} />
				<source srcSet={'/assets/img/bkgs/bkg_top_XL.png 1x, /assets/img/bkgs/bkg_top_XL@2x.png 2x'} media={'(min-width: 1200px'} />
				<source srcSet={'/assets/img/bkgs/bkg_top_LG.png 1x, /assets/img/bkgs/bkg_top_LG@2x.png 2x'} media={'(min-width: 992px'} />
				<source srcSet={'/assets/img/bkgs/bkg_top_MD.png 1x, /assets/img/bkgs/bkg_top_MD@2x.png 2x'} media={'(min-width: 768px'} />
				<img srcSet={'/assets/img/bkgs/bkg_top_XS.png 1x, /assets/img/bkgs/bkg_top_XS@2x.png 2x'} src={EMPTY_IMG_URI} />
			</picture>

			<div className={'content'}>
				<section id={'hero'}>
					<div className={'container-fluid p-0'}>
						<div className={'row align-items-xl-center m-0'}>
							<div className={'hero-top col-12 col-xl-6 p-0'}>
								<div className={'container-fluid'}>
									<div className={'row align-items-md-center'}>
										<div className={'col-12 col-md-6 col-xl-12 col-xxl-10 offset-xxl-2'}>
											<div className={'hero-img-swap'}>
												<div className={'image-container'}>

													<Swiper lazy={true} {...heroSliderOptions}>
														{buildSwiperSlides(heroSliderSlides)}
													</Swiper>

												</div>
											</div>
										</div>
										<div className={'col-12 col-md-6 d-xl-none'}>
											<div className={'hero-highlight'}>
												<span>
													A {collectionNFTCount}&nbsp;
												</span>
												<span>
													NFT
													<br />
													collection built by you, in collaboration
													with VHILS
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className={'hero-bottom col-12 col-xl-5 p-0'}>
								<div className={'d-none d-xl-block'}>
									<div className={'hero-highlight'}>
										<span>
											A {collectionNFTCount}&nbsp;
										</span>
										<span>
											NFT collection built by you, in collaboration with VHILS
										</span>
									</div>
								</div>
								<div className={'hero-txt-slider'}>
									<Swiper {...heroTxtSliderOptions}>
										<SwiperSlide>
											<div className={'slide'}>
												<div className={'container-fluid p-xl-0'}>
													<div className={'row'}>
														<div className={'col-10 offset-1 col-xl-11 offset-xl-0'}>
															<div className={'title'}>
																1. Layers are your canvas
															</div>
															<div className={'text'}>
																Tear tokens are your tools.<br />
																Apply tear tokens on your Layers to reveal the artwork. 3 tear tokens to fully reveal it.
															</div>
														</div>
													</div>
												</div>
												<div className={'slide-arrow d-xl-none'}>
													<img src={'/assets/img/arrows/arrow_hero01.svg'} />
												</div>
												<div className={'btn-container'}>
													<div className={'container-fluid p-xl-0'}>
														<div className={'row'}>
															<div className={'col-10 offset-1 col-xl-12 offset-xl-0'}>
																<div className={'row opensea-links'}>
																	<div className={'wrapper col-6'}>
																		<a href={OPENSEA_LAYERS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-two has-icon d-flex'}>
																			<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
																			<span className={'btn-text'}>BUY LAYERS</span>
																		</a>
																	</div>
																	<div className={'wrapper col-6'}>
																		<a href={OPENSEA_TEARS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-two has-icon d-flex'}>
																			<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
																			<span className={'btn-text'}>BUY TEARS</span>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</SwiperSlide>
										<SwiperSlide>
											<div className={'slide'}>
												<div className={'container-fluid p-xl-0'}>
													<div className={'row'}>
														<div className={'col-10 offset-1 col-xl-12 offset-xl-0'}>
															<div className={'title'}>
																2. Stake your Layers
															</div>
															<div className={'text'}>
																You can receive tear tokens for free until you fully reveal your Layers and other special benefits after.
															</div>
														</div>
													</div>
												</div>
												<div className={'slide-arrow d-xl-none'}>
													<img src={'/assets/img/arrows/arrow_hero02.svg'} />
												</div>
												<div className={'btn-container'}>
													<div className={'container-fluid p-xl-0'}>
														<div className={'row'}>
															<div className={'col-10 d-flex justify-content-center offset-1 justify-content-xl-start offset-xl-0'}>
																<div className={'row opensea-links'}>
																	<div className={'wrapper'}>
																		<Link to={'/staking'} className={'btn d-flex'}>
																			<span className={'btn-text'}>Staking</span>
																		</Link>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</SwiperSlide>
										<SwiperSlide>
											<div className={'slide'}>
												<div className={'container-fluid p-xl-0'}>
													<div className={'row'}>
														<div className={'col-10 offset-1 col-xl-12 offset-xl-0'}>
															<div className={'title'}>
																3. Burn your Layers
															</div>
															<div className={'text'}>
																Coming soon
															</div>
														</div>
													</div>
												</div>
												<div className={'slide-arrow d-xl-none'}>
													<img src={'/assets/img/arrows/arrow_hero03.svg'} />
												</div>
												<div className={'btn-container'}>
													<div className={'container-fluid p-xl-0'}>
														<div className={'row'}>
															<div className={'col-10 d-flex justify-content-center offset-1 justify-content-xl-start offset-xl-0'}>
																<div className={'row opensea-links'}>
																	<div className={'wrapper'}>
																		{/* <Link to={'/burning'} className={'btn d-flex'}>
																			<span className={'btn-text'}>Burning</span>
																		</Link> */}
																		<a href={'#sub_block'} className={'btn d-flex'}>
																			<span className={'btn-text'}>sign up for newsletter</span>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</SwiperSlide>
										<SwiperSlide>
											<div className={'slide'}>
												<div className={'container-fluid p-xl-0'}>
													<div className={'row'}>
														<div className={'col-10 offset-1 col-xl-12 offset-xl-0'}>
															<div className={'title'}>
																4. Print your Layers
															</div>
															<div className={'text'}>
																Coming soon
															</div>
														</div>
													</div>
												</div>
												<div className={'btn-container'}>
													<div className={'container-fluid p-xl-0'}>
														<div className={'row'}>
															<div className={'col-10 d-flex justify-content-center offset-1 justify-content-xl-start offset-xl-0'}>
																<div className={'row opensea-links'}>
																	<div className={'wrapper'}>
																		<a href={'#sub_block'} className={'btn d-flex'}>
																			<span className={'btn-text'}>sign up for newsletter</span>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</SwiperSlide>
									</Swiper>
								</div>
							</div>
							<div className={'hero-cta'}>
								<div className={'container-fluid'}>
									<div className={'row'}>
										<div className={'col-12 d-flex justify-content-center align-items-center'}>
											<a href={'#what_is_layers'} className={'cta-btn'}>
												<span>Know More</span>
												<span className={'cta-arrow'}>
													<img src={'/assets/img/arrows/arrow_cta_down.svg'} />
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id={'what_is_layers'}>
					<div className={'container-fluid'}>
						<div className={'row'}>
							<div className={'col-12'}>
								<div className={'wrapper'}>
									<div className={'bg'}></div>
									<div className={'fg'}>

										<div className={'row what-is-layers'}>
											<div className={'col-10 offset-1 col-md-3 col-xl-2 offset-xl-2'}><h1 className={'text-uppercase'}>What <br className={'d-none d-md-block'} />is<br />LAYERS</h1></div>
											<div className={'col-10 offset-1 col-md-7 offset-md-0 col-xl-6'}>
												<p>LAYERS is a collection of generative NFTs based on the work Vhils develops with physical billboard pieces. Uniting the digital and physical art worlds, this project reveals the intricacies of the artist's creative universe by replicating the physical practice on the blockchain.</p>
												<p>LAYERS invites the holder to become an active participant in Vhils' NFT ecosystem, giving the opportunity and choice to influence the final aesthetic and utility of the NFTs.</p>
												<p>Creating a unique collector journey, as the project evolves, there are opportunities to gain access to a selection of activities and special benefits within Vhils' world.</p>
											</div>
										</div>

										<div className={'how-to-reveal-your-artwork'}>
											<div className={'bg'}></div>
											<div className={'fg'}>
												<div className={'row'}>
													<div className={'col-8 offset-2 col-md-3 offset-md-1 col-xl-2 offset-xl-2'}><h2 className={'text-uppercase'}>How to <br className={'d-none d-lg-block'} />reveal <br className={'d-none d-md-block'} />your <br className={'d-none d-md-block'} />artwork</h2></div>
													<div className={'col-8 offset-2 col-md-7 offset-md-0 col-xl-5'}>
														<div className={'row row-cols-1 row-cols-md-3'}>
															<div className={'instructions d-flex justify-content-center p-0 align-items-md-stretch'}>
																<div className={'row align-items-center flex-md-column justify-content-md-between'}>
																	<div className={'step col-6 col-md-12'}>
																		<div className={'number'}>01.</div>
																		<div className={'text'}>Buy <br className={'d-none d-xl-block'} />a genesis <br className={'d-none d-xl-block'} />Layers</div>
																	</div>
																	<div className={'action col-6 col-md-12'}>
																		<a href={OPENSEA_LAYERS_MARKET} className={'btn has-icon d-flex'}>
																			<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
																			<span className={'text'}>BUY LAYERS</span>
																		</a>
																	</div>
																</div>
															</div>
															<div className={'instructions d-flex justify-content-center p-0 align-items-md-stretch'}>
																<div className={'row align-items-center flex-md-column justify-content-md-between'}>
																	<div className={'step col-6 col-md-12'}>
																		<div className={'number'}>02.</div>
																		<div className={'text'}>Buy <br className={'d-none d-md-block'} />a Tear</div>
																	</div>
																	<div className={'action col-6 col-md-12'}>
																		<a href={OPENSEA_TEARS_MARKET} className={'btn has-icon d-flex'}>
																			<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
																			<span className={'text'}>BUY TEARS</span>
																		</a>
																	</div>
																</div>
															</div>
															<div className={'instructions d-flex justify-content-center p-0 align-items-md-stretch'}>
																<div className={'row align-items-center flex-md-column justify-content-md-between'}>
																	<div className={'step col-6 col-md-12'}>
																		<div className={'number'}>03.</div>
																		<div className={'text'}>Apply <br className={'d-none d-md-block'} />your Tear</div>
																	</div>
																	<div className={'action col-6 col-md-12'}>
																		<Link to={'/revealing'} className={'btn justify-content-center'}>
																			<span className={'text'}>APPLY TEAR</span>
																		</Link>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className={'collection-metrics'}>
											<div className={'row'}>
												<div className={'col-10 offset-1 col-md-3 col-xl-8 offset-xl-2'}>
													<h2 className={'text-uppercase'}>Collection metrics</h2>
												</div>

												<div className={'metric-holder flex-column col-5 offset-1 col-md-7-for-3 offset-md-0 col-xl-8-for-5 offset-xl-2'}>
													<div className={'top d-flex align-items-center'}>
														<img src={'/assets/img/icons/icon_dollar.svg'} className={'icon'} />
														<span className={'metric-value'}>0.135 <small>ETH</small></span>
													</div>
													<div className={'metric-label'}>Genesis floor price</div>
												</div>
												<div className={'metric-holder flex-column col-5 col-md-7-for-3 col-xl-8-for-5'}>
													<div className={'top d-flex align-items-center'}>
														<img src={'/assets/img/icons/icon_person.svg'} className={'icon'} />
														<span className={'metric-value'}>960</span>
													</div>
													<div className={'metric-label'}>Genesis unique holders</div>
												</div>
												<div className={'metric-holder flex-column col-5 offset-1 col-md-7-for-3 offset-md-0 col-xl-8-for-5'}>
													<div className={'top d-flex align-items-center'}>
														<img src={'/assets/img/icons/icon_person.svg'} className={'icon'} />
														<span className={'metric-value'}>487</span>
													</div>
													<div className={'metric-label'}>Unique holders tears</div>
												</div>
												<div className={'metric-holder flex-column col-5 col-md-7-for-3 offset-md-4 col-xl-8-for-5 offset-xl-0'}>
													<div className={'top d-flex align-items-center'}>
														<img src={'/assets/img/icons/icon_dollar.svg'} className={'icon'} />
														<span className={'metric-value'}>0.12 <small>ETH</small></span>
													</div>
													<div className={'metric-label'}>Tear floor price</div>
												</div>
												<div className={'metric-holder flex-column col-5 offset-1 col-md-7-for-3 offset-md-0 col-xl-8-for-5'}>
													<div className={'top d-flex align-items-center'}>
														<img src={'/assets/img/icons/icon_dollar.svg'} className={'icon'} />
														<span className={'metric-value'}>0.3 <small>ETH</small></span>
													</div>
													<div className={'metric-label'}>Fully revealed floor price</div>
												</div>
											</div>

											<div className={'row charts'}>
												<div className={'chart-info col-10 offset-1 col-md-7 offset-md-4 col-xl-4 offset-xl-2 order-xl-1'}>
													<h3 className={'title'}>Genesis</h3>
													<p>
														90 day avg. price: <strong>{genesis90DayAveragePrice} ETH</strong><br />
														90 day volume: <strong>{genesis90DayVolume} ETH</strong>
													</p>
												</div>
												<div className={'chart-holder col-12 col-md-7 offset-md-4 col-xl-4 offset-xl-2 order-xl-2'}>
													<img src={'/assets/img/temp/collection_metrics_chart_1.svg'} className={'chart'} />
												</div>

												<div className={'chart-info col-10 offset-1 col-md-7 offset-md-4 col-xl-4 offset-xl-0 order-xl-1'}>
													<h3 className={'title'}>Tears</h3>
													<p>
														90 day avg. price: <strong>{tears90DayAveragePrice} ETH</strong><br />
														90 day volume: <strong>{tears90DayVolume} ETH</strong>
													</p>
												</div>
												<div className={'chart-holder col-12 col-md-7 offset-md-4 col-xl-4 offset-xl-0 order-xl-2'}>
													<img src={'/assets/img/temp/collection_metrics_chart_2.svg'} className={'chart'} />
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<NFTSlider />

				<section id={'who_is_vhils'}>
					<div className={'container-fluid'}>
						<div className={'row'}>
							<div className={'col-10 offset-1 col-xl-8 offset-xl-2'}>
								<h1 className={'title'}>WHO <br />IS VHILS</h1>
							</div>
						</div>
					</div>

					<div className={'top-slider-wrapper'}>
						<Swiper {...vhilsTopSliderOptions}>
							{buildSwiperSlides(vhilsTopSliderSlides)}
						</Swiper>
						<div className={'slider-mask-top'}>
							<picture className={'responsive'}>
								<source srcSet={'/assets/img/bkgs/mask_top_vector_xl.svg 1x'} media={'(min-width: 1400px)'} />
								<source srcSet={'/assets/img/bkgs/mask_top_vector_lg.svg 1x'} media={'(min-width: 1200px)'} />
								<source srcSet={'/assets/img/bkgs/mask_top_vector_md.svg 1x'} media={'(min-width: 768px)'} />
								<img srcSet={'/assets/img/bkgs/mask_top_vector_xs.svg 1x'} />
							</picture>
						</div>
						<div className={'slider-mask-bottom'}>
							<picture className={'responsive'}>
								<source srcSet={'/assets/img/bkgs/mask_bottom_vector_xl.svg 1x'} media={'(min-width: 1400px)'} />
								<source srcSet={'/assets/img/bkgs/mask_bottom_vector_lg.svg 1x'} media={'(min-width: 1200px)'} />
								<source srcSet={'/assets/img/bkgs/mask_bottom_vector_md.svg 1x'} media={'(min-width: 768px)'} />
								<img srcSet={'/assets/img/bkgs/mask_bottom_vector_xs.svg 1x'} />
							</picture>
						</div>
						<div className={'swiper-pagination-holder container-fluid'}>
							<div className={'row'}>
								<div className={'col-10 offset-1 col-xl-8 offset-xl-2'}></div>
							</div>
						</div>
					</div>

					<div className={'about-vhils container-fluid'}>
						<div className={'row'}>
							<div className={'col-10 offset-1 col-xl-8 offset-xl-2 col-xxl-6 offset-xxl-3'}>
								<p>Alexandre Farto aka Vhils (b. 1987) has developed a unique visual language based on the removal of surface layers of walls and other media with the non-conventional tools and techniques. He began interacting with the urban environment through the practice of graffiti in the early 2000's.</p>
								<p>Like a modern-day urban archaeologist, Vhils reflects on the impact of urbanity, development and global homogenisation on landscapes and people's identities. Destroying to create, he delivers powerful and poetic visual statements from materials the city rejects, humanising depressed areas with his poignant large-scale portraits.</p>
							</div>
							<div className={'col-10 offset-1 col-xl-8 offset-xl-2 col-xxl-6 offset-xxl-3 text-center'}>
								<h3 className={'sub-title text-uppercase'}>Vhils' selected works <br className={'d-none d-md-block'} />&amp; exhibitions</h3>
							</div>
						</div>
					</div>

					<Swiper className={'vhils-subslider-1'} lazy={true} {...vhilsSubSliderOptions}>
						{buildSwiperSlides(vhilsSubSlider1Slides)}
					</Swiper>

					<div className={'container-fluid'}>
						<div className={'row'}>
							<div className={'col-10 offset-1 col-xl-8 offset-xl-2 col-xxl-6 offset-xxl-3 text-center'}>
								<h3 className={'sub-title text-uppercase'}>Vhils on press</h3>
							</div>
						</div>
					</div>

					<Swiper className={'vhils-subslider-2'} lazy={true} {...vhilsSubSliderOptions}>
						{buildSwiperSlides(vhilsSubSlider2Slides)}
					</Swiper>

					<div className={'more-about-vhils container-fluid'}>
						<div className={'row'}>
							<div className={'col-10 offset-1 col-md-5 col-xl-3 offset-xl-3'}>
								<a href={'https://vhils.com/'} target={'_blank'} rel={'noopener'} className={'btn d-block text-uppercase text-center'}>More about Vhils</a>
							</div>

							<SocialNetworks extraContainerClasses={'col-10 offset-1 col-md-4 col-xl-3 offset-xl-0 col-xxl-2 offset-xxl-1'} extraClasses={'justify-content-center justify-content-xxl-between'} />
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default IndexPage;

export const Head: HeadFC = () => (
	<>
		<title>LAYERS - A project by VHILS</title>
	</>
);
